import Link from "next/link";
import type { AppProps } from "next/app";
import Script from "next/script";

import { PrismicProvider } from "@prismicio/react";
import { PrismicPreview } from "@prismicio/next";
import { repositoryName } from "../../prismicio";

import "../styles/globals.scss";

const app = ({ Component, pageProps }: AppProps) => (
	<>
		<Script id="gtm">
			{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-MD5LWKK');`}
		</Script>
		<Script
			id="prismic"
			src="https://static.cdn.prismic.io/prismic.js?new=true&repo=tpg-web"
		/>
		<PrismicProvider
			internalLinkComponent={({ href, ...props }) => (
				<Link href={href}>
					<a {...props} />
				</Link>
			)}
		>
			<PrismicPreview repositoryName={repositoryName}>
				<Component {...pageProps} />
			</PrismicPreview>
		</PrismicProvider>
	</>
);

export default app;
